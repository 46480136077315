<template lang="pug">
    Link(:contents="contents.content", :class="contents.content.alignment || 'left'").botao {{ contents.content.name }}
</template>

<script>
import { props, components } from '@/mixins/component'

export default {
    name: "component-botao",
    props,
    components,
}
</script>

<style lang="stylus" scoped src="./Botao.styl"></style>
